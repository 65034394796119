import React, { useState } from 'react';
import get from 'lodash.get';
import { formatMoney2, formatNumber2 } from '../../utils/formatMoney';

const InputField = ({
  errorMessage,
  textarea,
  isDisabled,
  inputClassName,
  edited,
  onBlur,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [focus, setFocus] = useState(false);

  const touched = edited
    ? get(edited, props.name) && errorMessage
    : errorMessage;

  const label = props.placeholder;
  delete props.placeholder;
  delete props.onBlur;

  const handleFocus = () => {
    setActive(true);
    setFocus(true);
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }

    if (!props.value) {
      setActive(false);
    }

    setFocus(false);
  };

  const formatCurrency = (value) => {
    if (props.money) {
      return parseFloat(value) ? formatMoney2(value, 0) : value;
    }

    return value;
  };

  const handleChange = (e) => {
    const { value } = e.target;

    if (props.money) {
      const newValue = value.replace(/[^0-9.-]+/g, '');
      e.target.value = parseFloat(newValue) ? formatNumber2(newValue) : '';
    }

    props.onChange(e);
  };

  const hasValue =
    props.value !== '' && props.value !== undefined && props.value !== null;

  const input = (
    <div
      className={`h-12 relative border-2 rounded-md w-full py-sm px-3 
    ${isDisabled ? 'bg-gray-310' : ''}
    ${
      errorMessage && (touched || props.value)
        ? 'border-red-500'
        : focus
        ? 'border-blue-750'
        : 'border-gray-350'
    }`}
    >
      <input
        step={props.type === 'number' ? props.step || 'any' : undefined}
        {...props}
        value={formatCurrency(props.value)}
        onChange={handleChange}
        data-testid={`${props.name}-test-id`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="w-full h-full outline-none box-border pt-4 text-base
      text-gray-800 bg-transparent leading-tight border-0"
        disabled={isDisabled}
      />
      <label
        className={`absolute left-0.75 top-1/2 text-gray-450 pointer-events-none labelTrans 
      ${active || hasValue ? 'floatLabel text-xs font-bold' : 'label text-sm'}`}
      >
        {label}
      </label>
    </div>
  );

  const textArea = (
    <div
      className={`relative border-2 rounded-md w-full py-sm px-3 
  ${isDisabled ? 'bg-gray-310' : ''}
  ${
    errorMessage && (touched || props.value)
      ? 'border-red-500'
      : focus
      ? 'border-blue-750'
      : 'border-gray-350'
  }`}
    >
      <textarea
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`appearance-none w-full
      text-base text-gray-800 bg-transparent leading-tight h-20 outline-none pt-md px-2 ${
        errorMessage && (touched || props.value)
          ? 'border-red-500'
          : focus
          ? 'border-blue-750'
          : 'border-gray-350'
      }`}
      />
      <label
        className={`absolute left-0.62 text-gray-450 pointer-events-none labelTrans 
      ${
        active || props.value ? 'top-0.62 text-xs font-bold' : 'text-sm top-0.9'
      }`}
      >
        {label}
      </label>
    </div>
  );

  return (
    <div className={props.className} data-testid="input-field">
      {textarea ? textArea : input}
      {(touched || props.value) && errorMessage ? (
        <p className="mt-1 text-xs text-red-500">{errorMessage}</p>
      ) : null}
    </div>
  );
};

export default InputField;
